import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getAllRespondents = createAsyncThunk(
  "respondents/getAllRespondents",
  async (thunkAPI) => {
    const response = await secureApi
      .get("/admin/respondent/getall")
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const sendNotice = createAsyncThunk(
  "respondents/sendNotice",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/sendNotification/notify", params)
      .catch((e) => {
        return e;
      });
    if (response.data && response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const changeRespondentPassword = createAsyncThunk(
  "respondents/changeRespondentPassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/changeRespondentPassword", params)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getFilledSurveys = createAsyncThunk(
  "respondents/getFilledSurveys",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/respondent/getsurveys", params)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const changeRespondentStatus = createAsyncThunk(
  "respondents/changeRespondentStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/respondent/changerespondentstatus", params)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getFinalRespondents = createAsyncThunk(
  "respondents/getFinalRespondents",
  async (id, thunkAPI) => {
    const response = await secureApi
      .post("/admin/livesurvey/getRespondentsForLiveSurvey", {
        surveyId: id,
      })
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
